import axios from "contentful-management/node_modules/axios"
import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"

export const Form = styled.form`
  -webkit-appearance: none;
  input,
  select,
  textarea {
    width: 100%;
    -webkit-appearance: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19) !important;
    border-radius: 4px;
    margin-bottom: 15px;
    z-index: 20;
  }
`

const Testimonials = () => {
  const [inputs, setInputs] = useState({
    name: "",
    review: "",
    wedding_date: "",
    location: "",
  })
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  })

  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      })
      setInputs({
        name: "",
        review: "",
        wedding_date: "",
        location: "",
      })
    } else {
      setStatus({
        info: { error: true, msg: msg },
      })
    }
  }

  const handleOnChange = e => {
    e.persist()
    setInputs(prev => ({
      ...prev,
      [e.target.id]: e.target.value,
    }))
    setStatus({
      submitted: false,
      submitting: false,
      info: { error: false, msg: null },
    })
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    setStatus(prevStatus => ({ ...prevStatus, submitting: true }))
    axios({
      method: "POST",
      url: "https://formspree.io/f/xjvjzayz",
      data: inputs,
    })
      .then(response => {
        handleServerResponse(true, "Thank you for submitting. ")
      })
      .catch(error => {
        handleServerResponse(false, error.response.data.error)
      })
  }

  return (
    <Layout>
      <Form
        className="flex min-h-3/4 flex-col justify-center items-center my-12 w-3/4 mx-auto overflow-visible sm:w-7/12 md:w-4/12"
        onSubmit={handleOnSubmit}
      >
        {
          <>
            <h1 className="uppercase text-4xl text-center mb-12">
              Testimonials
            </h1>
            <p className="mb-5 lg:w-3/4 text-center">
              Let us know how we did. Your feedback is very important to us.
            </p>
          </>
        }

        <input
          type="text"
          placeholder="Your Full Name"
          name="name"
          id="name"
          required
          value={inputs.name}
          onChange={handleOnChange}
          className="px-3 py-3 placeholder-gray-800 text-gray-700 relative bg-white text-sm outline-none focus:outline-none  w-full "
        />
        <input
          type="text"
          placeholder="Wedding Date:"
          name="wedding_date"
          id="wedding_date"
          required
          value={inputs.wedding_date}
          onChange={handleOnChange}
          className="px-3 py-3 placeholder-gray-800 text-gray-700 relative bg-white text-sm outline-none focus:outline-none  w-full "
        />
        <input
          type="text"
          placeholder="Location:"
          name="location"
          id="location"
          required
          value={inputs.location}
          onChange={handleOnChange}
          className="px-3 py-3 placeholder-gray-800 text-gray-700 relative bg-white text-sm outline-none focus:outline-none  w-full "
        />

        <textarea
          rows="5"
          placeholder="Let us know how we did."
          required
          className="px-3 py-3 placeholder-gray-800 text-gray-700 relative bg-white text-sm outline-none focus:outline-none  w-full "
          name="review"
          id="review"
          value={inputs.review}
          onChange={handleOnChange}
        />

        <button
          className="bg-gray-900 w-full rounded text-white py-1 uppercase"
          type="submit"
          disabled={status.submitting}
        >
          {!status.submitting
            ? !status.submitted
              ? "Submit"
              : "Submitted"
            : "Submitting..."}
        </button>
        {status.info.error && (
          <div className="error">Error: {status.info.msg}</div>
        )}
        {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
      </Form>
    </Layout>
  )
}

export default Testimonials
